/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const azingoStripeQueryAccount = /* GraphQL */ `
  query AzingoStripeQueryAccount($organizationID: String!) {
    azingoStripeQueryAccount(organizationID: $organizationID)
  }
`;
export const azingoContentQuery = /* GraphQL */ `
  query AzingoContentQuery(
    $organizationID: String!
    $userID: String
    $serviceIDs: [String]
  ) {
    azingoContentQuery(
      organizationID: $organizationID
      userID: $userID
      serviceIDs: $serviceIDs
    )
  }
`;
export const azingoGetSignedURLS3 = /* GraphQL */ `
  query AzingoGetSignedURLS3($bucket: String!, $key: String!) {
    azingoGetSignedURLS3(bucket: $bucket, key: $key)
  }
`;
export const azingoOrganizationParametersQuery = /* GraphQL */ `
  query AzingoOrganizationParametersQuery($organizationID: String!) {
    azingoOrganizationParametersQuery(organizationID: $organizationID)
  }
`;
export const azingoTeammateOrganizationQuery = /* GraphQL */ `
  query AzingoTeammateOrganizationQuery($organizationID: String!) {
    azingoTeammateOrganizationQuery(organizationID: $organizationID)
  }
`;
export const azingoTeamAvailabilityQuery = /* GraphQL */ `
  query AzingoTeamAvailabilityQuery(
    $organizationID: String!
    $startDate: String
    $endDate: String
  ) {
    azingoTeamAvailabilityQuery(
      organizationID: $organizationID
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
export const azingoTimeSlotQuery = /* GraphQL */ `
  query AzingoTimeSlotQuery(
    $serviceIDs: [String]!
    $startDate: String
    $endDate: String
  ) {
    azingoTimeSlotQuery(
      serviceIDs: $serviceIDs
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
export const azingoCalendarQuery = /* GraphQL */ `
  query AzingoCalendarQuery(
    $organizationID: String!
    $userID: String
    $serviceIDs: [String]
    $startDate: String
    $endDate: String
  ) {
    azingoCalendarQuery(
      organizationID: $organizationID
      userID: $userID
      serviceIDs: $serviceIDs
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
export const azingoStripeAccountGet = /* GraphQL */ `
  query AzingoStripeAccountGet(
    $stripeAccountID: String
    $organizationID: String
  ) {
    azingoStripeAccountGet(
      stripeAccountID: $stripeAccountID
      organizationID: $organizationID
    )
  }
`;
export const azingoStripeQueryAppointments = /* GraphQL */ `
  query AzingoStripeQueryAppointments(
    $organizationID: String!
    $email: String!
    $stripeCustomerID: String
  ) {
    azingoStripeQueryAppointments(
      organizationID: $organizationID
      email: $email
      stripeCustomerID: $stripeCustomerID
    )
  }
`;
export const azingoStripeQueryServices = /* GraphQL */ `
  query AzingoStripeQueryServices(
    $organizationID: String!
    $email: String!
    $stripeCustomerID: String
  ) {
    azingoStripeQueryServices(
      organizationID: $organizationID
      email: $email
      stripeCustomerID: $stripeCustomerID
    )
  }
`;
export const azingoStripeInvoiceVoid = /* GraphQL */ `
  query AzingoStripeInvoiceVoid(
    $organizationID: String!
    $stripeInvoiceID: String!
  ) {
    azingoStripeInvoiceVoid(
      organizationID: $organizationID
      stripeInvoiceID: $stripeInvoiceID
    )
  }
`;
export const azingoStripeInvoiceDelete = /* GraphQL */ `
  query AzingoStripeInvoiceDelete(
    $organizationID: String!
    $stripeInvoiceID: String!
  ) {
    azingoStripeInvoiceDelete(
      organizationID: $organizationID
      stripeInvoiceID: $stripeInvoiceID
    )
  }
`;
export const azingoStripeCheckout = /* GraphQL */ `
  query AzingoStripeCheckout(
    $amount: Int!
    $currency: String!
    $customerName: String!
    $customerEmail: String!
    $customerAddress: StripeAddress!
  ) {
    azingoStripeCheckout(
      amount: $amount
      currency: $currency
      customerName: $customerName
      customerEmail: $customerEmail
      customerAddress: $customerAddress
    )
  }
`;
export const azingoStripeQueryPlans = /* GraphQL */ `
  query AzingoStripeQueryPlans {
    azingoStripeQueryPlans
  }
`;
export const azingoGetStripePublishKey = /* GraphQL */ `
  query AzingoGetStripePublishKey {
    azingoGetStripePublishKey
  }
`;
export const azingoStripeCheckoutSessionCreate = /* GraphQL */ `
  query AzingoStripeCheckoutSessionCreate(
    $priceID: String!
    $customerName: String!
    $customerEmail: String!
    $customerAddress: StripeAddress!
    $organizationID: String!
    $quantity: Int
    $successURL: String
    $failureURL: String
    $mode: String
    $trialEnd: Int
  ) {
    azingoStripeCheckoutSessionCreate(
      priceID: $priceID
      customerName: $customerName
      customerEmail: $customerEmail
      customerAddress: $customerAddress
      organizationID: $organizationID
      quantity: $quantity
      successURL: $successURL
      failureURL: $failureURL
      mode: $mode
      trialEnd: $trialEnd
    )
  }
`;
export const azingoStripeQuerySubscriptions = /* GraphQL */ `
  query AzingoStripeQuerySubscriptions($name: String!) {
    azingoStripeQuerySubscriptions(name: $name)
  }
`;
export const azingoStripeCancelSubscription = /* GraphQL */ `
  query AzingoStripeCancelSubscription($subscriptionIDs: [String]!) {
    azingoStripeCancelSubscription(subscriptionIDs: $subscriptionIDs)
  }
`;
export const azingoOrganizationDelete = /* GraphQL */ `
  query AzingoOrganizationDelete(
    $organizationID: String!
    $teammateID: String!
  ) {
    azingoOrganizationDelete(
      organizationID: $organizationID
      teammateID: $teammateID
    )
  }
`;
export const azingoGetIOSSDKKey = /* GraphQL */ `
  query AzingoGetIOSSDKKey {
    azingoGetIOSSDKKey
  }
`;
export const azingoGetAndroidSDKKey = /* GraphQL */ `
  query AzingoGetAndroidSDKKey {
    azingoGetAndroidSDKKey
  }
`;
export const azingoStripeQuoteList = /* GraphQL */ `
  query AzingoStripeQuoteList(
    $organizationID: String!
    $stripeCustomerID: String
    $status: [String]
  ) {
    azingoStripeQuoteList(
      organizationID: $organizationID
      stripeCustomerID: $stripeCustomerID
      status: $status
    )
  }
`;
export const azingoDashboardGetUsers = /* GraphQL */ `
  query AzingoDashboardGetUsers {
    azingoDashboardGetUsers
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      type
      details
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listActivities = /* GraphQL */ `
  query ListActivities(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        details
        organizationID
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAffiliateLink = /* GraphQL */ `
  query GetAffiliateLink($id: ID!) {
    getAffiliateLink(id: $id) {
      id
      affiliateProductID
      azingoAffiliateProduct {
        id
        src
        categoryIDs
        countrySourceMap {
          country
          src
          __typename
        }
        type
        title
        subtitle
        description
        keywords
        thumbnail
        organizationID
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      categoryIDs
      organizationID
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAffiliateLinks = /* GraphQL */ `
  query ListAffiliateLinks(
    $filter: ModelAffiliateLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAffiliateLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        affiliateProductID
        azingoAffiliateProduct {
          id
          src
          categoryIDs
          countrySourceMap {
            country
            src
            __typename
          }
          type
          title
          subtitle
          description
          keywords
          thumbnail
          organizationID
          baseType
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        categoryIDs
        organizationID
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const affiliateLinkByOrganization = /* GraphQL */ `
  query AffiliateLinkByOrganization(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAffiliateLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    affiliateLinkByOrganization(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        affiliateProductID
        azingoAffiliateProduct {
          id
          src
          categoryIDs
          countrySourceMap {
            country
            src
            __typename
          }
          type
          title
          subtitle
          description
          keywords
          thumbnail
          organizationID
          baseType
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        categoryIDs
        organizationID
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAffiliateProduct = /* GraphQL */ `
  query GetAffiliateProduct($id: ID!) {
    getAffiliateProduct(id: $id) {
      id
      src
      categoryIDs
      countrySourceMap {
        country
        src
        __typename
      }
      type
      title
      subtitle
      description
      keywords
      thumbnail
      organizationID
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAffiliateProducts = /* GraphQL */ `
  query ListAffiliateProducts(
    $filter: ModelAffiliateProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAffiliateProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        src
        categoryIDs
        countrySourceMap {
          country
          src
          __typename
        }
        type
        title
        subtitle
        description
        keywords
        thumbnail
        organizationID
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const affiliateProductsByType = /* GraphQL */ `
  query AffiliateProductsByType(
    $type: AffiliateProductType!
    $sortDirection: ModelSortDirection
    $filter: ModelAffiliateProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    affiliateProductsByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        src
        categoryIDs
        countrySourceMap {
          country
          src
          __typename
        }
        type
        title
        subtitle
        description
        keywords
        thumbnail
        organizationID
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const affiliateProductByOrganization = /* GraphQL */ `
  query AffiliateProductByOrganization(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAffiliateProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    affiliateProductByOrganization(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        src
        categoryIDs
        countrySourceMap {
          country
          src
          __typename
        }
        type
        title
        subtitle
        description
        keywords
        thumbnail
        organizationID
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAffiliateLinkRequest = /* GraphQL */ `
  query GetAffiliateLinkRequest($id: ID!) {
    getAffiliateLinkRequest(id: $id) {
      id
      url
      message
      organizationID
      organization {
        id
        name
        handle
        type
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        keywords
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        hide
        cloned
        cloneConfig
        entitlements
        purchaserID
        planID
        paymentConfig
        onboarding
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      teammateID
      teammate {
        id
        name
        email
        handle
        phone
        hide
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        picture
        organizationIDs
        roles {
          organizationID
          roles
          __typename
        }
        baseType
        googleCalendarConfig
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      userID
      status
      hide
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAffiliateLinkRequests = /* GraphQL */ `
  query ListAffiliateLinkRequests(
    $filter: ModelAffiliateLinkRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAffiliateLinkRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        message
        organizationID
        organization {
          id
          name
          handle
          type
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          keywords
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          hide
          cloned
          cloneConfig
          entitlements
          purchaserID
          planID
          paymentConfig
          onboarding
          baseType
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        teammateID
        teammate {
          id
          name
          email
          handle
          phone
          hide
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          picture
          organizationIDs
          roles {
            organizationID
            roles
            __typename
          }
          baseType
          googleCalendarConfig
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        userID
        status
        hide
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const affiliateLinkRequestByOrganization = /* GraphQL */ `
  query AffiliateLinkRequestByOrganization(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAffiliateLinkRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    affiliateLinkRequestByOrganization(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        message
        organizationID
        organization {
          id
          name
          handle
          type
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          keywords
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          hide
          cloned
          cloneConfig
          entitlements
          purchaserID
          planID
          paymentConfig
          onboarding
          baseType
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        teammateID
        teammate {
          id
          name
          email
          handle
          phone
          hide
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          picture
          organizationIDs
          roles {
            organizationID
            roles
            __typename
          }
          baseType
          googleCalendarConfig
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        userID
        status
        hide
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const affiliateLinkRequestByCreatedAt = /* GraphQL */ `
  query AffiliateLinkRequestByCreatedAt(
    $baseType: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAffiliateLinkRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    affiliateLinkRequestByCreatedAt(
      baseType: $baseType
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        message
        organizationID
        organization {
          id
          name
          handle
          type
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          keywords
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          hide
          cloned
          cloneConfig
          entitlements
          purchaserID
          planID
          paymentConfig
          onboarding
          baseType
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        teammateID
        teammate {
          id
          name
          email
          handle
          phone
          hide
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          picture
          organizationIDs
          roles {
            organizationID
            roles
            __typename
          }
          baseType
          googleCalendarConfig
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        userID
        status
        hide
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      type
      bookingDate
      email
      suppressEmail
      timeSlotID
      organizationID
      contractID
      serviceID
      location {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      timeSlot {
        id
        title
        description
        startDate
        endDate
        type
        maxAttendees
        attendingContracts
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        registrationType
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        organizationID
        serviceID
        teamID
        resourceIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        details
        createdAt
        updatedAt
        owner
        __typename
      }
      contract {
        id
        name
        userID
        email
        phoneNumber
        creditBalance
        priceID
        price {
          id
          active
          currency
          name
          paymentModel
          paymentRef
          productRef
          frequency
          scheme
          type
          depositMethod
          hourlyRate
          hours
          depositAmount
          depositPercentage
          amount
          tiers {
            name
            amount
            maxQuantity
            shareable
            shareableQuantity
            __typename
          }
          organizationID
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        activeDate
        formattedActiveDate
        active
        status
        duration
        address {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        type
        cancelReason
        bundleID
        serviceID
        stripeCustomerID
        stripePaymentIntentID
        stripeSubscriptionID
        organizationID
        notificationEmailList
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        bookingDate
        email
        suppressEmail
        timeSlotID
        organizationID
        contractID
        serviceID
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        timeSlot {
          id
          title
          description
          startDate
          endDate
          type
          maxAttendees
          attendingContracts
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          registrationType
          location {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          organizationID
          serviceID
          teamID
          resourceIDs
          service {
            id
            active
            affiliate
            allowTeamSelection
            allowBookableTimeSelection
            autoAssignTeam
            autoConfirm
            baseType
            billingType
            billingFrequency
            bookingBufferBefore
            bookingBufferAfter
            bookingCountMax
            bookingType
            categoryIDs
            contentFrequency
            cost
            defaultDuration
            deliveryType
            description
            displayOrder
            featured
            formattedDescription
            handle
            images
            isDeleted
            locationType
            languageCode
            linked
            maxAttendees
            name
            nextStepURL
            organizationID
            prohibitedSubscriptions
            requiredSubscriptions
            registrationType
            scheduleHorizon
            suppressScheduleUpdate
            tagline
            teamIDs
            thumbnail
            timezone
            metrics
            createdAt
            updatedAt
            owner
            __typename
          }
          baseType
          details
          createdAt
          updatedAt
          owner
          __typename
        }
        contract {
          id
          name
          userID
          email
          phoneNumber
          creditBalance
          priceID
          price {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          activeDate
          formattedActiveDate
          active
          status
          duration
          address {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          type
          cancelReason
          bundleID
          serviceID
          stripeCustomerID
          stripePaymentIntentID
          stripeSubscriptionID
          organizationID
          notificationEmailList
          service {
            id
            active
            affiliate
            allowTeamSelection
            allowBookableTimeSelection
            autoAssignTeam
            autoConfirm
            baseType
            billingType
            billingFrequency
            bookingBufferBefore
            bookingBufferAfter
            bookingCountMax
            bookingType
            categoryIDs
            contentFrequency
            cost
            defaultDuration
            deliveryType
            description
            displayOrder
            featured
            formattedDescription
            handle
            images
            isDeleted
            locationType
            languageCode
            linked
            maxAttendees
            name
            nextStepURL
            organizationID
            prohibitedSubscriptions
            requiredSubscriptions
            registrationType
            scheduleHorizon
            suppressScheduleUpdate
            tagline
            teamIDs
            thumbnail
            timezone
            metrics
            createdAt
            updatedAt
            owner
            __typename
          }
          baseType
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bookingsByOrganization = /* GraphQL */ `
  query BookingsByOrganization(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingsByOrganization(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        bookingDate
        email
        suppressEmail
        timeSlotID
        organizationID
        contractID
        serviceID
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        timeSlot {
          id
          title
          description
          startDate
          endDate
          type
          maxAttendees
          attendingContracts
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          registrationType
          location {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          organizationID
          serviceID
          teamID
          resourceIDs
          service {
            id
            active
            affiliate
            allowTeamSelection
            allowBookableTimeSelection
            autoAssignTeam
            autoConfirm
            baseType
            billingType
            billingFrequency
            bookingBufferBefore
            bookingBufferAfter
            bookingCountMax
            bookingType
            categoryIDs
            contentFrequency
            cost
            defaultDuration
            deliveryType
            description
            displayOrder
            featured
            formattedDescription
            handle
            images
            isDeleted
            locationType
            languageCode
            linked
            maxAttendees
            name
            nextStepURL
            organizationID
            prohibitedSubscriptions
            requiredSubscriptions
            registrationType
            scheduleHorizon
            suppressScheduleUpdate
            tagline
            teamIDs
            thumbnail
            timezone
            metrics
            createdAt
            updatedAt
            owner
            __typename
          }
          baseType
          details
          createdAt
          updatedAt
          owner
          __typename
        }
        contract {
          id
          name
          userID
          email
          phoneNumber
          creditBalance
          priceID
          price {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          activeDate
          formattedActiveDate
          active
          status
          duration
          address {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          type
          cancelReason
          bundleID
          serviceID
          stripeCustomerID
          stripePaymentIntentID
          stripeSubscriptionID
          organizationID
          notificationEmailList
          service {
            id
            active
            affiliate
            allowTeamSelection
            allowBookableTimeSelection
            autoAssignTeam
            autoConfirm
            baseType
            billingType
            billingFrequency
            bookingBufferBefore
            bookingBufferAfter
            bookingCountMax
            bookingType
            categoryIDs
            contentFrequency
            cost
            defaultDuration
            deliveryType
            description
            displayOrder
            featured
            formattedDescription
            handle
            images
            isDeleted
            locationType
            languageCode
            linked
            maxAttendees
            name
            nextStepURL
            organizationID
            prohibitedSubscriptions
            requiredSubscriptions
            registrationType
            scheduleHorizon
            suppressScheduleUpdate
            tagline
            teamIDs
            thumbnail
            timezone
            metrics
            createdAt
            updatedAt
            owner
            __typename
          }
          baseType
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bookingByCreatedAt = /* GraphQL */ `
  query BookingByCreatedAt(
    $baseType: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByCreatedAt(
      baseType: $baseType
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        bookingDate
        email
        suppressEmail
        timeSlotID
        organizationID
        contractID
        serviceID
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        timeSlot {
          id
          title
          description
          startDate
          endDate
          type
          maxAttendees
          attendingContracts
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          registrationType
          location {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          organizationID
          serviceID
          teamID
          resourceIDs
          service {
            id
            active
            affiliate
            allowTeamSelection
            allowBookableTimeSelection
            autoAssignTeam
            autoConfirm
            baseType
            billingType
            billingFrequency
            bookingBufferBefore
            bookingBufferAfter
            bookingCountMax
            bookingType
            categoryIDs
            contentFrequency
            cost
            defaultDuration
            deliveryType
            description
            displayOrder
            featured
            formattedDescription
            handle
            images
            isDeleted
            locationType
            languageCode
            linked
            maxAttendees
            name
            nextStepURL
            organizationID
            prohibitedSubscriptions
            requiredSubscriptions
            registrationType
            scheduleHorizon
            suppressScheduleUpdate
            tagline
            teamIDs
            thumbnail
            timezone
            metrics
            createdAt
            updatedAt
            owner
            __typename
          }
          baseType
          details
          createdAt
          updatedAt
          owner
          __typename
        }
        contract {
          id
          name
          userID
          email
          phoneNumber
          creditBalance
          priceID
          price {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          activeDate
          formattedActiveDate
          active
          status
          duration
          address {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          type
          cancelReason
          bundleID
          serviceID
          stripeCustomerID
          stripePaymentIntentID
          stripeSubscriptionID
          organizationID
          notificationEmailList
          service {
            id
            active
            affiliate
            allowTeamSelection
            allowBookableTimeSelection
            autoAssignTeam
            autoConfirm
            baseType
            billingType
            billingFrequency
            bookingBufferBefore
            bookingBufferAfter
            bookingCountMax
            bookingType
            categoryIDs
            contentFrequency
            cost
            defaultDuration
            deliveryType
            description
            displayOrder
            featured
            formattedDescription
            handle
            images
            isDeleted
            locationType
            languageCode
            linked
            maxAttendees
            name
            nextStepURL
            organizationID
            prohibitedSubscriptions
            requiredSubscriptions
            registrationType
            scheduleHorizon
            suppressScheduleUpdate
            tagline
            teamIDs
            thumbnail
            timezone
            metrics
            createdAt
            updatedAt
            owner
            __typename
          }
          baseType
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBookingRestrictions = /* GraphQL */ `
  query GetBookingRestrictions($id: ID!) {
    getBookingRestrictions(id: $id) {
      id
      nextAvailableDate
      lastFrequencyDate
      frequencySlotsAvailable
      totalSlotsAvailable
      contractID
      serviceID
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBookingRestrictions = /* GraphQL */ `
  query ListBookingRestrictions(
    $filter: ModelBookingRestrictionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookingRestrictions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nextAvailableDate
        lastFrequencyDate
        frequencySlotsAvailable
        totalSlotsAvailable
        contractID
        serviceID
        organizationID
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bookingRestrictionsByOrganization = /* GraphQL */ `
  query BookingRestrictionsByOrganization(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBookingRestrictionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingRestrictionsByOrganization(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nextAvailableDate
        lastFrequencyDate
        frequencySlotsAvailable
        totalSlotsAvailable
        contractID
        serviceID
        organizationID
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBundle = /* GraphQL */ `
  query GetBundle($id: ID!) {
    getBundle(id: $id) {
      id
      priceIDs
      prices {
        items {
          id
          active
          currency
          name
          paymentModel
          paymentRef
          productRef
          frequency
          scheme
          type
          depositMethod
          hourlyRate
          hours
          depositAmount
          depositPercentage
          amount
          tiers {
            name
            amount
            maxQuantity
            shareable
            shareableQuantity
            __typename
          }
          organizationID
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      name
      totalCredits
      organizationID
      bundleWeights {
        serviceID
        credits
        __typename
      }
      serviceIDs
      services {
        items {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBundles = /* GraphQL */ `
  query ListBundles(
    $filter: ModelBundleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBundles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        priceIDs
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        name
        totalCredits
        organizationID
        bundleWeights {
          serviceID
          credits
          __typename
        }
        serviceIDs
        services {
          items {
            id
            active
            affiliate
            allowTeamSelection
            allowBookableTimeSelection
            autoAssignTeam
            autoConfirm
            baseType
            billingType
            billingFrequency
            bookingBufferBefore
            bookingBufferAfter
            bookingCountMax
            bookingType
            categoryIDs
            contentFrequency
            cost
            defaultDuration
            deliveryType
            description
            displayOrder
            featured
            formattedDescription
            handle
            images
            isDeleted
            locationType
            languageCode
            linked
            maxAttendees
            name
            nextStepURL
            organizationID
            prohibitedSubscriptions
            requiredSubscriptions
            registrationType
            scheduleHorizon
            suppressScheduleUpdate
            tagline
            teamIDs
            thumbnail
            timezone
            metrics
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bundlesByOrganization = /* GraphQL */ `
  query BundlesByOrganization(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBundleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bundlesByOrganization(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        priceIDs
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        name
        totalCredits
        organizationID
        bundleWeights {
          serviceID
          credits
          __typename
        }
        serviceIDs
        services {
          items {
            id
            active
            affiliate
            allowTeamSelection
            allowBookableTimeSelection
            autoAssignTeam
            autoConfirm
            baseType
            billingType
            billingFrequency
            bookingBufferBefore
            bookingBufferAfter
            bookingCountMax
            bookingType
            categoryIDs
            contentFrequency
            cost
            defaultDuration
            deliveryType
            description
            displayOrder
            featured
            formattedDescription
            handle
            images
            isDeleted
            locationType
            languageCode
            linked
            maxAttendees
            name
            nextStepURL
            organizationID
            prohibitedSubscriptions
            requiredSubscriptions
            registrationType
            scheduleHorizon
            suppressScheduleUpdate
            tagline
            teamIDs
            thumbnail
            timezone
            metrics
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      displayOrder
      isDeleted
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        displayOrder
        isDeleted
        organizationID
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const categoryByOrganization = /* GraphQL */ `
  query CategoryByOrganization(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoryByOrganization(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        displayOrder
        isDeleted
        organizationID
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContent = /* GraphQL */ `
  query GetContent($id: ID!) {
    getContent(id: $id) {
      id
      organizationID
      contractIDs
      serviceIDs
      uploadedBy
      src
      type
      mimeType
      title
      subtitle
      description
      thumbnail
      tagline
      categoryIDs
      storageType
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listContents = /* GraphQL */ `
  query ListContents(
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationID
        contractIDs
        serviceIDs
        uploadedBy
        src
        type
        mimeType
        title
        subtitle
        description
        thumbnail
        tagline
        categoryIDs
        storageType
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const contentByOrganization = /* GraphQL */ `
  query ContentByOrganization(
    $organizationID: ID!
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentByOrganization(
      organizationID: $organizationID
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        contractIDs
        serviceIDs
        uploadedBy
        src
        type
        mimeType
        title
        subtitle
        description
        thumbnail
        tagline
        categoryIDs
        storageType
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContract = /* GraphQL */ `
  query GetContract($id: ID!) {
    getContract(id: $id) {
      id
      name
      userID
      email
      phoneNumber
      creditBalance
      priceID
      price {
        id
        active
        currency
        name
        paymentModel
        paymentRef
        productRef
        frequency
        scheme
        type
        depositMethod
        hourlyRate
        hours
        depositAmount
        depositPercentage
        amount
        tiers {
          name
          amount
          maxQuantity
          shareable
          shareableQuantity
          __typename
        }
        organizationID
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      activeDate
      formattedActiveDate
      active
      status
      duration
      address {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      type
      cancelReason
      bundleID
      serviceID
      stripeCustomerID
      stripePaymentIntentID
      stripeSubscriptionID
      organizationID
      notificationEmailList
      service {
        id
        active
        affiliate
        allowTeamSelection
        allowBookableTimeSelection
        autoAssignTeam
        autoConfirm
        baseType
        billingType
        billingFrequency
        bookingBufferBefore
        bookingBufferAfter
        bookingCountMax
        bookingRestrictions {
          capacity
          totalSlots
          frequency
          frequencyPeriod
          __typename
        }
        bookingType
        categoryIDs
        contentFrequency
        cost
        defaultDuration
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        description
        displayOrder
        featured
        formattedDescription
        handle
        images
        isDeleted
        locationType
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        languageCode
        linked
        maxAttendees
        name
        nextStepURL
        organizationID
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prohibitedSubscriptions
        requiredSubscriptions
        registrationType
        scheduleHorizon
        suppressScheduleUpdate
        serviceSchedule {
          startDate
          endDate
          serviceHours {
            day
            is24Hours
            __typename
          }
          __typename
        }
        serviceAvailability {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        stripePrices {
          id
          active
          currency
          product
          recurring {
            aggregate_usage
            interval
            interval_count
            usage_type
            trial_period_days
            __typename
          }
          type
          unit_amount
          billing_scheme
          created
          livemode
          lookup_key
          metadata
          nickname
          object
          tax_behavior
          tiers_mode
          transform_quantity
          unit_amount_decimal
          __typename
        }
        tagline
        teamIDs
        thumbnail
        timezone
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listContracts = /* GraphQL */ `
  query ListContracts(
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        userID
        email
        phoneNumber
        creditBalance
        priceID
        price {
          id
          active
          currency
          name
          paymentModel
          paymentRef
          productRef
          frequency
          scheme
          type
          depositMethod
          hourlyRate
          hours
          depositAmount
          depositPercentage
          amount
          tiers {
            name
            amount
            maxQuantity
            shareable
            shareableQuantity
            __typename
          }
          organizationID
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        activeDate
        formattedActiveDate
        active
        status
        duration
        address {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        type
        cancelReason
        bundleID
        serviceID
        stripeCustomerID
        stripePaymentIntentID
        stripeSubscriptionID
        organizationID
        notificationEmailList
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const contractsByUserID = /* GraphQL */ `
  query ContractsByUserID(
    $userID: String!
    $organizationID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contractsByUserID(
      userID: $userID
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        userID
        email
        phoneNumber
        creditBalance
        priceID
        price {
          id
          active
          currency
          name
          paymentModel
          paymentRef
          productRef
          frequency
          scheme
          type
          depositMethod
          hourlyRate
          hours
          depositAmount
          depositPercentage
          amount
          tiers {
            name
            amount
            maxQuantity
            shareable
            shareableQuantity
            __typename
          }
          organizationID
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        activeDate
        formattedActiveDate
        active
        status
        duration
        address {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        type
        cancelReason
        bundleID
        serviceID
        stripeCustomerID
        stripePaymentIntentID
        stripeSubscriptionID
        organizationID
        notificationEmailList
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const contractsByOrganization = /* GraphQL */ `
  query ContractsByOrganization(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contractsByOrganization(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        userID
        email
        phoneNumber
        creditBalance
        priceID
        price {
          id
          active
          currency
          name
          paymentModel
          paymentRef
          productRef
          frequency
          scheme
          type
          depositMethod
          hourlyRate
          hours
          depositAmount
          depositPercentage
          amount
          tiers {
            name
            amount
            maxQuantity
            shareable
            shareableQuantity
            __typename
          }
          organizationID
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        activeDate
        formattedActiveDate
        active
        status
        duration
        address {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        type
        cancelReason
        bundleID
        serviceID
        stripeCustomerID
        stripePaymentIntentID
        stripeSubscriptionID
        organizationID
        notificationEmailList
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      type
      isRead
      details
      organizationID
      teamID
      baseType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        isRead
        details
        organizationID
        teamID
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationsByOrganization = /* GraphQL */ `
  query NotificationsByOrganization(
    $organizationID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByOrganization(
      organizationID: $organizationID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        isRead
        details
        organizationID
        teamID
        baseType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      handle
      type
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      keywords
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      hide
      cloned
      cloneConfig
      entitlements
      purchaserID
      planID
      paymentConfig
      onboarding
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        handle
        type
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        keywords
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        hide
        cloned
        cloneConfig
        entitlements
        purchaserID
        planID
        paymentConfig
        onboarding
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const organizationByHandle = /* GraphQL */ `
  query OrganizationByHandle(
    $handle: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationByHandle(
      handle: $handle
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        handle
        type
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        keywords
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        hide
        cloned
        cloneConfig
        entitlements
        purchaserID
        planID
        paymentConfig
        onboarding
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const organizationByCreatedAt = /* GraphQL */ `
  query OrganizationByCreatedAt(
    $baseType: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationByCreatedAt(
      baseType: $baseType
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        handle
        type
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        keywords
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        hide
        cloned
        cloneConfig
        entitlements
        purchaserID
        planID
        paymentConfig
        onboarding
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganizationParameters = /* GraphQL */ `
  query GetOrganizationParameters($id: ID!) {
    getOrganizationParameters(id: $id) {
      id
      name
      value
      secure
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listOrganizationParameters = /* GraphQL */ `
  query ListOrganizationParameters(
    $filter: ModelOrganizationParametersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationParameters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        value
        secure
        organizationID
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const organizationParametersByOrganization = /* GraphQL */ `
  query OrganizationParametersByOrganization(
    $organizationID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationParametersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationParametersByOrganization(
      organizationID: $organizationID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        value
        secure
        organizationID
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      type
      details
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        details
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPrice = /* GraphQL */ `
  query GetPrice($id: ID!) {
    getPrice(id: $id) {
      id
      active
      currency
      name
      paymentModel
      paymentRef
      productRef
      frequency
      scheme
      type
      depositMethod
      hourlyRate
      hours
      depositAmount
      depositPercentage
      amount
      tiers {
        name
        amount
        maxQuantity
        shareable
        shareableQuantity
        __typename
      }
      organizationID
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPrices = /* GraphQL */ `
  query ListPrices(
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        active
        currency
        name
        paymentModel
        paymentRef
        productRef
        frequency
        scheme
        type
        depositMethod
        hourlyRate
        hours
        depositAmount
        depositPercentage
        amount
        tiers {
          name
          amount
          maxQuantity
          shareable
          shareableQuantity
          __typename
        }
        organizationID
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const priceByProductRef = /* GraphQL */ `
  query PriceByProductRef(
    $productRef: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    priceByProductRef(
      productRef: $productRef
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        currency
        name
        paymentModel
        paymentRef
        productRef
        frequency
        scheme
        type
        depositMethod
        hourlyRate
        hours
        depositAmount
        depositPercentage
        amount
        tiers {
          name
          amount
          maxQuantity
          shareable
          shareableQuantity
          __typename
        }
        organizationID
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPurchaseSession = /* GraphQL */ `
  query GetPurchaseSession($id: ID!) {
    getPurchaseSession(id: $id) {
      id
      organizationID
      userID
      priceID
      serviceID
      service {
        id
        active
        affiliate
        allowTeamSelection
        allowBookableTimeSelection
        autoAssignTeam
        autoConfirm
        baseType
        billingType
        billingFrequency
        bookingBufferBefore
        bookingBufferAfter
        bookingCountMax
        bookingRestrictions {
          capacity
          totalSlots
          frequency
          frequencyPeriod
          __typename
        }
        bookingType
        categoryIDs
        contentFrequency
        cost
        defaultDuration
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        description
        displayOrder
        featured
        formattedDescription
        handle
        images
        isDeleted
        locationType
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        languageCode
        linked
        maxAttendees
        name
        nextStepURL
        organizationID
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prohibitedSubscriptions
        requiredSubscriptions
        registrationType
        scheduleHorizon
        suppressScheduleUpdate
        serviceSchedule {
          startDate
          endDate
          serviceHours {
            day
            is24Hours
            __typename
          }
          __typename
        }
        serviceAvailability {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        stripePrices {
          id
          active
          currency
          product
          recurring {
            aggregate_usage
            interval
            interval_count
            usage_type
            trial_period_days
            __typename
          }
          type
          unit_amount
          billing_scheme
          created
          livemode
          lookup_key
          metadata
          nickname
          object
          tax_behavior
          tiers_mode
          transform_quantity
          unit_amount_decimal
          __typename
        }
        tagline
        teamIDs
        thumbnail
        timezone
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      timeSlotID
      timeSlot {
        id
        title
        description
        startDate
        endDate
        type
        maxAttendees
        attendingContracts
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        registrationType
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        organizationID
        serviceID
        teamID
        resourceIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        details
        createdAt
        updatedAt
        owner
        __typename
      }
      teamAvailabilityID
      teamAvailability {
        id
        autoConfirm
        startDate
        endDate
        bookingDuration
        bookingBufferBefore
        bookingBufferAfter
        organizationID
        serviceID
        teamIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      phoneNumber
      teamID
      scheduledTimeSlotIDs
      location {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      startDate
      endDate
      purchased
      calendarSlotType
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPurchaseSessions = /* GraphQL */ `
  query ListPurchaseSessions(
    $filter: ModelPurchaseSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchaseSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        userID
        priceID
        serviceID
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        timeSlotID
        timeSlot {
          id
          title
          description
          startDate
          endDate
          type
          maxAttendees
          attendingContracts
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          registrationType
          location {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          organizationID
          serviceID
          teamID
          resourceIDs
          service {
            id
            active
            affiliate
            allowTeamSelection
            allowBookableTimeSelection
            autoAssignTeam
            autoConfirm
            baseType
            billingType
            billingFrequency
            bookingBufferBefore
            bookingBufferAfter
            bookingCountMax
            bookingType
            categoryIDs
            contentFrequency
            cost
            defaultDuration
            deliveryType
            description
            displayOrder
            featured
            formattedDescription
            handle
            images
            isDeleted
            locationType
            languageCode
            linked
            maxAttendees
            name
            nextStepURL
            organizationID
            prohibitedSubscriptions
            requiredSubscriptions
            registrationType
            scheduleHorizon
            suppressScheduleUpdate
            tagline
            teamIDs
            thumbnail
            timezone
            metrics
            createdAt
            updatedAt
            owner
            __typename
          }
          baseType
          details
          createdAt
          updatedAt
          owner
          __typename
        }
        teamAvailabilityID
        teamAvailability {
          id
          autoConfirm
          startDate
          endDate
          bookingDuration
          bookingBufferBefore
          bookingBufferAfter
          organizationID
          serviceID
          teamIDs
          service {
            id
            active
            affiliate
            allowTeamSelection
            allowBookableTimeSelection
            autoAssignTeam
            autoConfirm
            baseType
            billingType
            billingFrequency
            bookingBufferBefore
            bookingBufferAfter
            bookingCountMax
            bookingType
            categoryIDs
            contentFrequency
            cost
            defaultDuration
            deliveryType
            description
            displayOrder
            featured
            formattedDescription
            handle
            images
            isDeleted
            locationType
            languageCode
            linked
            maxAttendees
            name
            nextStepURL
            organizationID
            prohibitedSubscriptions
            requiredSubscriptions
            registrationType
            scheduleHorizon
            suppressScheduleUpdate
            tagline
            teamIDs
            thumbnail
            timezone
            metrics
            createdAt
            updatedAt
            owner
            __typename
          }
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        phoneNumber
        teamID
        scheduledTimeSlotIDs
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        startDate
        endDate
        purchased
        calendarSlotType
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const purchaseSessionByOrgAndUser = /* GraphQL */ `
  query PurchaseSessionByOrgAndUser(
    $organizationID: ID!
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPurchaseSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    purchaseSessionByOrgAndUser(
      organizationID: $organizationID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        userID
        priceID
        serviceID
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        timeSlotID
        timeSlot {
          id
          title
          description
          startDate
          endDate
          type
          maxAttendees
          attendingContracts
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          registrationType
          location {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          organizationID
          serviceID
          teamID
          resourceIDs
          service {
            id
            active
            affiliate
            allowTeamSelection
            allowBookableTimeSelection
            autoAssignTeam
            autoConfirm
            baseType
            billingType
            billingFrequency
            bookingBufferBefore
            bookingBufferAfter
            bookingCountMax
            bookingType
            categoryIDs
            contentFrequency
            cost
            defaultDuration
            deliveryType
            description
            displayOrder
            featured
            formattedDescription
            handle
            images
            isDeleted
            locationType
            languageCode
            linked
            maxAttendees
            name
            nextStepURL
            organizationID
            prohibitedSubscriptions
            requiredSubscriptions
            registrationType
            scheduleHorizon
            suppressScheduleUpdate
            tagline
            teamIDs
            thumbnail
            timezone
            metrics
            createdAt
            updatedAt
            owner
            __typename
          }
          baseType
          details
          createdAt
          updatedAt
          owner
          __typename
        }
        teamAvailabilityID
        teamAvailability {
          id
          autoConfirm
          startDate
          endDate
          bookingDuration
          bookingBufferBefore
          bookingBufferAfter
          organizationID
          serviceID
          teamIDs
          service {
            id
            active
            affiliate
            allowTeamSelection
            allowBookableTimeSelection
            autoAssignTeam
            autoConfirm
            baseType
            billingType
            billingFrequency
            bookingBufferBefore
            bookingBufferAfter
            bookingCountMax
            bookingType
            categoryIDs
            contentFrequency
            cost
            defaultDuration
            deliveryType
            description
            displayOrder
            featured
            formattedDescription
            handle
            images
            isDeleted
            locationType
            languageCode
            linked
            maxAttendees
            name
            nextStepURL
            organizationID
            prohibitedSubscriptions
            requiredSubscriptions
            registrationType
            scheduleHorizon
            suppressScheduleUpdate
            tagline
            teamIDs
            thumbnail
            timezone
            metrics
            createdAt
            updatedAt
            owner
            __typename
          }
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        phoneNumber
        teamID
        scheduledTimeSlotIDs
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        startDate
        endDate
        purchased
        calendarSlotType
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecommendation = /* GraphQL */ `
  query GetRecommendation($id: ID!) {
    getRecommendation(id: $id) {
      id
      organizationID
      serviceIDs
      affiliateProductIDs
      affiliateLinkIDs
      contractIDs
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listRecommendations = /* GraphQL */ `
  query ListRecommendations(
    $filter: ModelRecommendationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecommendations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationID
        serviceIDs
        affiliateProductIDs
        affiliateLinkIDs
        contractIDs
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const recsByOrganization = /* GraphQL */ `
  query RecsByOrganization(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRecommendationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recsByOrganization(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        serviceIDs
        affiliateProductIDs
        affiliateLinkIDs
        contractIDs
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getResource = /* GraphQL */ `
  query GetResource($id: ID!) {
    getResource(id: $id) {
      id
      name
      description
      quantity
      organizationID
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listResources = /* GraphQL */ `
  query ListResources(
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        quantity
        organizationID
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const resourcesByOrganization = /* GraphQL */ `
  query ResourcesByOrganization(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourcesByOrganization(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        quantity
        organizationID
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getResourceBooking = /* GraphQL */ `
  query GetResourceBooking($id: ID!) {
    getResourceBooking(id: $id) {
      id
      startDate
      endDate
      bookingDate
      quantity
      organizationID
      teamIDs
      timeSlotID
      resourceID
      location {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      timeSlot {
        id
        title
        description
        startDate
        endDate
        type
        maxAttendees
        attendingContracts
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        registrationType
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        organizationID
        serviceID
        teamID
        resourceIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        details
        createdAt
        updatedAt
        owner
        __typename
      }
      resource {
        id
        name
        description
        quantity
        organizationID
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listResourceBookings = /* GraphQL */ `
  query ListResourceBookings(
    $filter: ModelResourceBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResourceBookings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        bookingDate
        quantity
        organizationID
        teamIDs
        timeSlotID
        resourceID
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        timeSlot {
          id
          title
          description
          startDate
          endDate
          type
          maxAttendees
          attendingContracts
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          registrationType
          location {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          organizationID
          serviceID
          teamID
          resourceIDs
          service {
            id
            active
            affiliate
            allowTeamSelection
            allowBookableTimeSelection
            autoAssignTeam
            autoConfirm
            baseType
            billingType
            billingFrequency
            bookingBufferBefore
            bookingBufferAfter
            bookingCountMax
            bookingType
            categoryIDs
            contentFrequency
            cost
            defaultDuration
            deliveryType
            description
            displayOrder
            featured
            formattedDescription
            handle
            images
            isDeleted
            locationType
            languageCode
            linked
            maxAttendees
            name
            nextStepURL
            organizationID
            prohibitedSubscriptions
            requiredSubscriptions
            registrationType
            scheduleHorizon
            suppressScheduleUpdate
            tagline
            teamIDs
            thumbnail
            timezone
            metrics
            createdAt
            updatedAt
            owner
            __typename
          }
          baseType
          details
          createdAt
          updatedAt
          owner
          __typename
        }
        resource {
          id
          name
          description
          quantity
          organizationID
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const resourceBookingsByOrganization = /* GraphQL */ `
  query ResourceBookingsByOrganization(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResourceBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceBookingsByOrganization(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        bookingDate
        quantity
        organizationID
        teamIDs
        timeSlotID
        resourceID
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        timeSlot {
          id
          title
          description
          startDate
          endDate
          type
          maxAttendees
          attendingContracts
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          registrationType
          location {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          organizationID
          serviceID
          teamID
          resourceIDs
          service {
            id
            active
            affiliate
            allowTeamSelection
            allowBookableTimeSelection
            autoAssignTeam
            autoConfirm
            baseType
            billingType
            billingFrequency
            bookingBufferBefore
            bookingBufferAfter
            bookingCountMax
            bookingType
            categoryIDs
            contentFrequency
            cost
            defaultDuration
            deliveryType
            description
            displayOrder
            featured
            formattedDescription
            handle
            images
            isDeleted
            locationType
            languageCode
            linked
            maxAttendees
            name
            nextStepURL
            organizationID
            prohibitedSubscriptions
            requiredSubscriptions
            registrationType
            scheduleHorizon
            suppressScheduleUpdate
            tagline
            teamIDs
            thumbnail
            timezone
            metrics
            createdAt
            updatedAt
            owner
            __typename
          }
          baseType
          details
          createdAt
          updatedAt
          owner
          __typename
        }
        resource {
          id
          name
          description
          quantity
          organizationID
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      active
      affiliate
      allowTeamSelection
      allowBookableTimeSelection
      autoAssignTeam
      autoConfirm
      baseType
      billingType
      billingFrequency
      bookingBufferBefore
      bookingBufferAfter
      bookingCountMax
      bookingRestrictions {
        capacity
        totalSlots
        frequency
        frequencyPeriod
        __typename
      }
      bookingType
      categoryIDs
      contentFrequency
      cost
      defaultDuration
      deliveryType
      deliveryOptions {
        platform
        url
        customerMessage
        __typename
      }
      description
      displayOrder
      featured
      formattedDescription
      handle
      images
      isDeleted
      locationType
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      languageCode
      linked
      maxAttendees
      name
      nextStepURL
      organizationID
      prices {
        items {
          id
          active
          currency
          name
          paymentModel
          paymentRef
          productRef
          frequency
          scheme
          type
          depositMethod
          hourlyRate
          hours
          depositAmount
          depositPercentage
          amount
          tiers {
            name
            amount
            maxQuantity
            shareable
            shareableQuantity
            __typename
          }
          organizationID
          baseType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      prohibitedSubscriptions
      requiredSubscriptions
      registrationType
      scheduleHorizon
      suppressScheduleUpdate
      serviceSchedule {
        startDate
        endDate
        serviceHours {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        __typename
      }
      serviceAvailability {
        day
        is24Hours
        window {
          startTime
          endTime
          __typename
        }
        __typename
      }
      stripePrices {
        id
        active
        currency
        product
        recurring {
          aggregate_usage
          interval
          interval_count
          usage_type
          trial_period_days
          __typename
        }
        type
        unit_amount
        billing_scheme
        created
        livemode
        lookup_key
        metadata
        nickname
        object
        tax_behavior
        tiers_mode
        transform_quantity
        unit_amount_decimal
        __typename
      }
      tagline
      teamIDs
      thumbnail
      timezone
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        active
        affiliate
        allowTeamSelection
        allowBookableTimeSelection
        autoAssignTeam
        autoConfirm
        baseType
        billingType
        billingFrequency
        bookingBufferBefore
        bookingBufferAfter
        bookingCountMax
        bookingRestrictions {
          capacity
          totalSlots
          frequency
          frequencyPeriod
          __typename
        }
        bookingType
        categoryIDs
        contentFrequency
        cost
        defaultDuration
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        description
        displayOrder
        featured
        formattedDescription
        handle
        images
        isDeleted
        locationType
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        languageCode
        linked
        maxAttendees
        name
        nextStepURL
        organizationID
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prohibitedSubscriptions
        requiredSubscriptions
        registrationType
        scheduleHorizon
        suppressScheduleUpdate
        serviceSchedule {
          startDate
          endDate
          serviceHours {
            day
            is24Hours
            __typename
          }
          __typename
        }
        serviceAvailability {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        stripePrices {
          id
          active
          currency
          product
          recurring {
            aggregate_usage
            interval
            interval_count
            usage_type
            trial_period_days
            __typename
          }
          type
          unit_amount
          billing_scheme
          created
          livemode
          lookup_key
          metadata
          nickname
          object
          tax_behavior
          tiers_mode
          transform_quantity
          unit_amount_decimal
          __typename
        }
        tagline
        teamIDs
        thumbnail
        timezone
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const serviceByOrg = /* GraphQL */ `
  query ServiceByOrg(
    $organizationID: ID!
    $handle: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    serviceByOrg(
      organizationID: $organizationID
      handle: $handle
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        affiliate
        allowTeamSelection
        allowBookableTimeSelection
        autoAssignTeam
        autoConfirm
        baseType
        billingType
        billingFrequency
        bookingBufferBefore
        bookingBufferAfter
        bookingCountMax
        bookingRestrictions {
          capacity
          totalSlots
          frequency
          frequencyPeriod
          __typename
        }
        bookingType
        categoryIDs
        contentFrequency
        cost
        defaultDuration
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        description
        displayOrder
        featured
        formattedDescription
        handle
        images
        isDeleted
        locationType
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        languageCode
        linked
        maxAttendees
        name
        nextStepURL
        organizationID
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prohibitedSubscriptions
        requiredSubscriptions
        registrationType
        scheduleHorizon
        suppressScheduleUpdate
        serviceSchedule {
          startDate
          endDate
          serviceHours {
            day
            is24Hours
            __typename
          }
          __typename
        }
        serviceAvailability {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        stripePrices {
          id
          active
          currency
          product
          recurring {
            aggregate_usage
            interval
            interval_count
            usage_type
            trial_period_days
            __typename
          }
          type
          unit_amount
          billing_scheme
          created
          livemode
          lookup_key
          metadata
          nickname
          object
          tax_behavior
          tiers_mode
          transform_quantity
          unit_amount_decimal
          __typename
        }
        tagline
        teamIDs
        thumbnail
        timezone
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      email
      handle
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      teammateIDs
      teammates {
        id
        name
        email
        handle
        phone
        hide
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        picture
        organizationIDs
        roles {
          organizationID
          roles
          __typename
        }
        baseType
        googleCalendarConfig
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      organizationID
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        handle
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        teammateIDs
        teammates {
          id
          name
          email
          handle
          phone
          hide
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          picture
          organizationIDs
          roles {
            organizationID
            roles
            __typename
          }
          baseType
          googleCalendarConfig
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        organizationID
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const teamByOrgAndName = /* GraphQL */ `
  query TeamByOrgAndName(
    $organizationID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamByOrgAndName(
      organizationID: $organizationID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        handle
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        teammateIDs
        teammates {
          id
          name
          email
          handle
          phone
          hide
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          picture
          organizationIDs
          roles {
            organizationID
            roles
            __typename
          }
          baseType
          googleCalendarConfig
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        organizationID
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeamAvailability = /* GraphQL */ `
  query GetTeamAvailability($id: ID!) {
    getTeamAvailability(id: $id) {
      id
      autoConfirm
      startDate
      endDate
      bookingDuration
      bookingBufferBefore
      bookingBufferAfter
      organizationID
      serviceID
      teamIDs
      service {
        id
        active
        affiliate
        allowTeamSelection
        allowBookableTimeSelection
        autoAssignTeam
        autoConfirm
        baseType
        billingType
        billingFrequency
        bookingBufferBefore
        bookingBufferAfter
        bookingCountMax
        bookingRestrictions {
          capacity
          totalSlots
          frequency
          frequencyPeriod
          __typename
        }
        bookingType
        categoryIDs
        contentFrequency
        cost
        defaultDuration
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        description
        displayOrder
        featured
        formattedDescription
        handle
        images
        isDeleted
        locationType
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        languageCode
        linked
        maxAttendees
        name
        nextStepURL
        organizationID
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prohibitedSubscriptions
        requiredSubscriptions
        registrationType
        scheduleHorizon
        suppressScheduleUpdate
        serviceSchedule {
          startDate
          endDate
          serviceHours {
            day
            is24Hours
            __typename
          }
          __typename
        }
        serviceAvailability {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        stripePrices {
          id
          active
          currency
          product
          recurring {
            aggregate_usage
            interval
            interval_count
            usage_type
            trial_period_days
            __typename
          }
          type
          unit_amount
          billing_scheme
          created
          livemode
          lookup_key
          metadata
          nickname
          object
          tax_behavior
          tiers_mode
          transform_quantity
          unit_amount_decimal
          __typename
        }
        tagline
        teamIDs
        thumbnail
        timezone
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      baseType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTeamAvailabilities = /* GraphQL */ `
  query ListTeamAvailabilities(
    $filter: ModelTeamAvailabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamAvailabilities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        autoConfirm
        startDate
        endDate
        bookingDuration
        bookingBufferBefore
        bookingBufferAfter
        organizationID
        serviceID
        teamIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const teamAvailabilityByOrgAndService = /* GraphQL */ `
  query TeamAvailabilityByOrgAndService(
    $organizationID: ID!
    $serviceID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamAvailabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamAvailabilityByOrgAndService(
      organizationID: $organizationID
      serviceID: $serviceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        autoConfirm
        startDate
        endDate
        bookingDuration
        bookingBufferBefore
        bookingBufferAfter
        organizationID
        serviceID
        teamIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        baseType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeammate = /* GraphQL */ `
  query GetTeammate($id: ID!) {
    getTeammate(id: $id) {
      id
      name
      email
      handle
      phone
      hide
      locations {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      picture
      organizationIDs
      roles {
        organizationID
        roles
        __typename
      }
      baseType
      googleCalendarConfig
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTeammates = /* GraphQL */ `
  query ListTeammates(
    $filter: ModelTeammateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeammates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        handle
        phone
        hide
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        picture
        organizationIDs
        roles {
          organizationID
          roles
          __typename
        }
        baseType
        googleCalendarConfig
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const teammateByEmail = /* GraphQL */ `
  query TeammateByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTeammateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teammateByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        handle
        phone
        hide
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        picture
        organizationIDs
        roles {
          organizationID
          roles
          __typename
        }
        baseType
        googleCalendarConfig
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const teammateByCreatedAt = /* GraphQL */ `
  query TeammateByCreatedAt(
    $baseType: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeammateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teammateByCreatedAt(
      baseType: $baseType
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        handle
        phone
        hide
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        picture
        organizationIDs
        roles {
          organizationID
          roles
          __typename
        }
        baseType
        googleCalendarConfig
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTimeSlot = /* GraphQL */ `
  query GetTimeSlot($id: ID!) {
    getTimeSlot(id: $id) {
      id
      title
      description
      startDate
      endDate
      type
      maxAttendees
      attendingContracts
      deliveryType
      deliveryOptions {
        platform
        url
        customerMessage
        __typename
      }
      registrationType
      location {
        name
        formatted
        street_address
        locality
        region
        postal_code
        country
        geoPoint {
          longitude
          latitude
          __typename
        }
        place_id
        __typename
      }
      locationRestrictions {
        type
        distanceMeters
        addressReference
        addressCriteria
        customerReason
        __typename
      }
      organizationID
      serviceID
      teamID
      resourceIDs
      service {
        id
        active
        affiliate
        allowTeamSelection
        allowBookableTimeSelection
        autoAssignTeam
        autoConfirm
        baseType
        billingType
        billingFrequency
        bookingBufferBefore
        bookingBufferAfter
        bookingCountMax
        bookingRestrictions {
          capacity
          totalSlots
          frequency
          frequencyPeriod
          __typename
        }
        bookingType
        categoryIDs
        contentFrequency
        cost
        defaultDuration
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        description
        displayOrder
        featured
        formattedDescription
        handle
        images
        isDeleted
        locationType
        locations {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        languageCode
        linked
        maxAttendees
        name
        nextStepURL
        organizationID
        prices {
          items {
            id
            active
            currency
            name
            paymentModel
            paymentRef
            productRef
            frequency
            scheme
            type
            depositMethod
            hourlyRate
            hours
            depositAmount
            depositPercentage
            amount
            organizationID
            baseType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prohibitedSubscriptions
        requiredSubscriptions
        registrationType
        scheduleHorizon
        suppressScheduleUpdate
        serviceSchedule {
          startDate
          endDate
          serviceHours {
            day
            is24Hours
            __typename
          }
          __typename
        }
        serviceAvailability {
          day
          is24Hours
          window {
            startTime
            endTime
            __typename
          }
          __typename
        }
        stripePrices {
          id
          active
          currency
          product
          recurring {
            aggregate_usage
            interval
            interval_count
            usage_type
            trial_period_days
            __typename
          }
          type
          unit_amount
          billing_scheme
          created
          livemode
          lookup_key
          metadata
          nickname
          object
          tax_behavior
          tiers_mode
          transform_quantity
          unit_amount_decimal
          __typename
        }
        tagline
        teamIDs
        thumbnail
        timezone
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      baseType
      details
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTimeSlots = /* GraphQL */ `
  query ListTimeSlots(
    $filter: ModelTimeSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimeSlots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        startDate
        endDate
        type
        maxAttendees
        attendingContracts
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        registrationType
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        organizationID
        serviceID
        teamID
        resourceIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        details
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const timeSlotByOrganization = /* GraphQL */ `
  query TimeSlotByOrganization(
    $organizationID: ID!
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimeSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timeSlotByOrganization(
      organizationID: $organizationID
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        startDate
        endDate
        type
        maxAttendees
        attendingContracts
        deliveryType
        deliveryOptions {
          platform
          url
          customerMessage
          __typename
        }
        registrationType
        location {
          name
          formatted
          street_address
          locality
          region
          postal_code
          country
          geoPoint {
            longitude
            latitude
            __typename
          }
          place_id
          __typename
        }
        locationRestrictions {
          type
          distanceMeters
          addressReference
          addressCriteria
          customerReason
          __typename
        }
        organizationID
        serviceID
        teamID
        resourceIDs
        service {
          id
          active
          affiliate
          allowTeamSelection
          allowBookableTimeSelection
          autoAssignTeam
          autoConfirm
          baseType
          billingType
          billingFrequency
          bookingBufferBefore
          bookingBufferAfter
          bookingCountMax
          bookingRestrictions {
            capacity
            totalSlots
            frequency
            frequencyPeriod
            __typename
          }
          bookingType
          categoryIDs
          contentFrequency
          cost
          defaultDuration
          deliveryType
          deliveryOptions {
            platform
            url
            customerMessage
            __typename
          }
          description
          displayOrder
          featured
          formattedDescription
          handle
          images
          isDeleted
          locationType
          locations {
            name
            formatted
            street_address
            locality
            region
            postal_code
            country
            place_id
            __typename
          }
          locationRestrictions {
            type
            distanceMeters
            addressReference
            addressCriteria
            customerReason
            __typename
          }
          languageCode
          linked
          maxAttendees
          name
          nextStepURL
          organizationID
          prices {
            nextToken
            __typename
          }
          prohibitedSubscriptions
          requiredSubscriptions
          registrationType
          scheduleHorizon
          suppressScheduleUpdate
          serviceSchedule {
            startDate
            endDate
            __typename
          }
          serviceAvailability {
            day
            is24Hours
            __typename
          }
          stripePrices {
            id
            active
            currency
            product
            type
            unit_amount
            billing_scheme
            created
            livemode
            lookup_key
            metadata
            nickname
            object
            tax_behavior
            tiers_mode
            transform_quantity
            unit_amount_decimal
            __typename
          }
          tagline
          teamIDs
          thumbnail
          timezone
          metrics
          createdAt
          updatedAt
          owner
          __typename
        }
        baseType
        details
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWebsite = /* GraphQL */ `
  query GetWebsite($id: ID!) {
    getWebsite(id: $id) {
      id
      name
      handle
      url
      template
      hide
      release
      content
      images
      palette {
        primary {
          main
          dark
          light
          contrastText
          __typename
        }
        secondary {
          main
          dark
          light
          contrastText
          __typename
        }
        success {
          main
          dark
          light
          contrastText
          __typename
        }
        info {
          main
          dark
          light
          contrastText
          __typename
        }
        warning {
          main
          dark
          light
          contrastText
          __typename
        }
        error {
          main
          dark
          light
          contrastText
          __typename
        }
        text {
          primary
          secondary
          disabled
          buttonText
          __typename
        }
        background {
          default
          paper
          __typename
        }
        __typename
      }
      privacyPolicy
      termsAndConditions
      config
      testimonials {
        serviceID
        avatar
        customer
        comment
        createdAt
        company
        __typename
      }
      organizationID
      baseType
      metrics
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listWebsites = /* GraphQL */ `
  query ListWebsites(
    $filter: ModelWebsiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebsites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        handle
        url
        template
        hide
        release
        content
        images
        palette {
          primary {
            main
            dark
            light
            contrastText
            __typename
          }
          secondary {
            main
            dark
            light
            contrastText
            __typename
          }
          success {
            main
            dark
            light
            contrastText
            __typename
          }
          info {
            main
            dark
            light
            contrastText
            __typename
          }
          warning {
            main
            dark
            light
            contrastText
            __typename
          }
          error {
            main
            dark
            light
            contrastText
            __typename
          }
          text {
            primary
            secondary
            disabled
            buttonText
            __typename
          }
          background {
            default
            paper
            __typename
          }
          __typename
        }
        privacyPolicy
        termsAndConditions
        config
        testimonials {
          serviceID
          avatar
          customer
          comment
          createdAt
          company
          __typename
        }
        organizationID
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const websiteByOrganization = /* GraphQL */ `
  query WebsiteByOrganization(
    $organizationID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebsiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    websiteByOrganization(
      organizationID: $organizationID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        handle
        url
        template
        hide
        release
        content
        images
        palette {
          primary {
            main
            dark
            light
            contrastText
            __typename
          }
          secondary {
            main
            dark
            light
            contrastText
            __typename
          }
          success {
            main
            dark
            light
            contrastText
            __typename
          }
          info {
            main
            dark
            light
            contrastText
            __typename
          }
          warning {
            main
            dark
            light
            contrastText
            __typename
          }
          error {
            main
            dark
            light
            contrastText
            __typename
          }
          text {
            primary
            secondary
            disabled
            buttonText
            __typename
          }
          background {
            default
            paper
            __typename
          }
          __typename
        }
        privacyPolicy
        termsAndConditions
        config
        testimonials {
          serviceID
          avatar
          customer
          comment
          createdAt
          company
          __typename
        }
        organizationID
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const websiteByCreatedAt = /* GraphQL */ `
  query WebsiteByCreatedAt(
    $baseType: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebsiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    websiteByCreatedAt(
      baseType: $baseType
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        handle
        url
        template
        hide
        release
        content
        images
        palette {
          primary {
            main
            dark
            light
            contrastText
            __typename
          }
          secondary {
            main
            dark
            light
            contrastText
            __typename
          }
          success {
            main
            dark
            light
            contrastText
            __typename
          }
          info {
            main
            dark
            light
            contrastText
            __typename
          }
          warning {
            main
            dark
            light
            contrastText
            __typename
          }
          error {
            main
            dark
            light
            contrastText
            __typename
          }
          text {
            primary
            secondary
            disabled
            buttonText
            __typename
          }
          background {
            default
            paper
            __typename
          }
          __typename
        }
        privacyPolicy
        termsAndConditions
        config
        testimonials {
          serviceID
          avatar
          customer
          comment
          createdAt
          company
          __typename
        }
        organizationID
        baseType
        metrics
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
